import { getData, setPayload, getPayload } from '../../globalhelper';

export async function getSiteCategories(siteID) {
    try {

        const categories = getPayload('categories');
        if(categories && categories.value) return categories.value;

        const path = `/categories/fetchBySiteId/${siteID}`;
        const res = await getData(path);
        if(res.error && res.error.legth > 0)
            throw new Error(res.error);

        const responseSet = {};
        const payload = res.data && res.data.categories ? res.data.categories : [];
        for(const data of payload) {
            responseSet[data.id] = {
                categoryName: data.categoryName,
                headline: data.headline ?? '',
                description: data.description ?? '',
            };
        }
        setPayload(responseSet, 'categories');

        return responseSet;

    } catch(err) {
        return {};
    }
}