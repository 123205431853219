import { postData } from '../../globalhelper';

export async function createOrder(payload) {
    try {

        const orderDetails = await postData('/orders', payload);
        if(orderDetails && orderDetails.data && orderDetails.data.order)
            return orderDetails;
            
        return {};

    } catch(err) {
        return {};
    }
}