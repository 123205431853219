import React from 'react';
import { calculateDiscountedPrice } from '../../globalhelper'; 

export function ProductCard(productID, productData) {
    const link = `/products?id=${productID}`;
    return (
        <a href={link} className="item" key={productID}> 
            <div className="card-body">
                <h6 className="title">{ productData.description ?? productData.productName } </h6>
                <img className="img-sm float-right" alt="product" src="template/images/items/1.jpg"/> 
                <p className="text-muted">{productData.currency} {productData.price}/- </p>
            </div>
        </a>
    )
}

export function ShowCasedProductCard(productID, productData) {
    const link = `/products?id=${productID}`;
    return (
        <div className="card card-sm card-product-grid" key={productID}>
            <a href={link} className="img-wrap"> <img src="images/items/1.jpg" alt="product" /> </a>
            <figcaption className="info-wrap">
                <a href={link} className="title">{productData.productName}</a>
                <div className="price mt-1">{productData.currency} {productData.price}</div>
            </figcaption>
        </div>
    )
}

export function ProductInformation(props) {
    const detail = props.details;
    const mainAmount = calculateDiscountedPrice(detail.price, detail.discountPer);
    const notesInfo = detail && detail.notes ? detail.notes.map(item => {
        return <li key={item}>{ item }</li>
    }) : '';

    return (
        <section className="section-content bg-white padding-y">
            <div className="container">
                <div className="row">
                    <aside className="col-md-6">
                        <div className="card">
                            <article className="gallery-wrap"> 
                                <div className="img-big-wrap">
                                    <div> 
                                        <a href="#">
                                            <img src="images/items/15.jpg" />
                                        </a>
                                    </div>
                                </div>
                                <div className="thumbs-wrap">
                                    <a href="#" className="item-thumb"> <img src="images/items/15.jpg" /></a>
                                    <a href="#" className="item-thumb"> <img src="images/items/15-1.jpg" /></a>
                                    <a href="#" className="item-thumb"> <img src="images/items/15-2.jpg" /></a>
                                    <a href="#" className="item-thumb"> <img src="images/items/15-1.jpg" /></a>
                                </div>
                            </article>
                        </div>
                    </aside>
                    <main className="col-md-6">
                        <article className="product-info-aside">
                            <h2 className="title mt-3">{ detail.productName ?? '' }</h2>
                            <div className="rating-wrap my-3">
                                {/* print star here */}
                                {/* <small className="label-rating text-muted">132 reviews</small> */}
                                {/* <small className="label-rating text-success"> <i className="fa fa-clipboard-check"></i> 154 orders </small> */}
                            </div>

                            <div className="mb-3"> 
                                <var className="price h4">{ detail.currency ?? '' } { mainAmount ?? '' }/-</var> 
                                { detail.discountPer ? 
                                    <span className="text-muted" style={{textDecoration:'line-through', marginLeft: '10px'}}>
                                        { detail.currency ?? '' } { detail.price ?? '' }/-
                                    </span> : '' 
                                }
                            </div>

                            <p>{ detail.description }</p>
                            <ul className="list-check" style={{marginBottom: '25px'}}>
                                { notesInfo }
                            </ul>

                            <dl className="row">

                                <dt className="col-sm-3">Product Code</dt>
                                <dd className="col-sm-9">{ detail.productCode }</dd>

                                <dt className="col-sm-3">Delivery time</dt>
                                <dd className="col-sm-9">7-10 days</dd>

                                <dt className="col-sm-3">Availabilty</dt>
                                <dd className="col-sm-9">in Stock</dd>
                            </dl>

                            <div className="form-row  mt-4">
                                <div className="form-group col-md flex-grow-0">
                                    <div className="input-group mb-3 input-spinner">
                                    <div className="input-group-prepend">
                                        <button className="btn btn-light" type="button" id="button-plus"
                                        onClick={props.increaseQuantity}> + </button>
                                    </div>
                                    <input type="text" className="form-control" value={props.quantity}
                                    onChange={props.changeQuantity}/>
                                    <div className="input-group-append">
                                        <button className="btn btn-light" type="button" id="button-minus"
                                        onClick={props.decreaseQuantity}> − </button>
                                    </div>
                                    </div>
                                </div>
                                <div className="form-group col-md">
                                    <button className="btn btn-primary" type="button" onClick={props.addToCart}>
                                        <i className="fas fa-shopping-cart"></i> <span className="text">Add to cart</span> 
                                    </button>
                                </div>
                            </div>
                        </article>
                    </main>
                </div>

            </div>
        </section>
    )
}

export function ProductDetails(props) {
    const detail = props.details;
    return (
        <section className="section-name padding-y bg" key={detail.id}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h5 className="title-description">Specifications</h5>
                        { productSpecUI(detail.specifications) }
                    </div>
                </div>
            </div>
        </section>
    )
}

function productSpecUI(specifications) {
    if(!specifications) return '';
    const specUI =  Object.keys(specifications).map((key) => {
        const specs = specifications[key];
        return (
            <>
                <tr key={key}>
                    <th colSpan="2" className="first-char-capital">{ key }</th>
                </tr>
                { productSpecListUI(specs) } 
            </>
        )
    });
    return (
        <>
            <table className="table table-bordered">
                <tbody>
                    { specUI }
                </tbody>
            </table>
        </>
    )
}

function productSpecListUI(specifications) {
    return Object.keys(specifications).map((key) => {
        const specs = specifications[key];
        return (
            <>
                <tr>
                    <td className="first-char-capital">{key}</td>
                    <td className="first-char-capital">{specs}</td>
                </tr>
            </>
        )
    });
}
  