require('dotenv').config();

// prod env configurations
const prodConfig = {
    env: "prod",
    api: {
        baseUrl: '',
        key: 'f96916d6-f2ce-4dfd-b45f-e44216815e26'
    },
    socket: {
        path: ''
    }
};

// dev env configurations
const devConfig = {
    env: "dev",
    host: "lol1.neooutlet.in",
    api: {
        baseUrl: 'http://localhost:3003/v1',
        key: '124b4f78-b5ac-47f2-988d-8bb4ea5a986f'
    },
    socket: {
        path: ''
    }
};

// return configurations based on the env
let config = devConfig;
if(process.env.REACT_APP_ENV === 'prod')
    config = prodConfig;

export default config;