import React from 'react';
import { Container } from '../../Components/UI/Container';
import { getSiteCategories } from '../Category/helper';
import { CategoryList } from '../../Components/Category';
import { NewlyAdded } from '../../Components/NewlyAdded';
import { getCurrentSiteDetails } from './helper';

export default class HomePage extends React.Component {
	constructor(props) {
		super();
		this.state = {
			categories: {}
		}
	}

	async componentDidMount() {
		try {

			const siteDetails = await getCurrentSiteDetails();
			const siteID = siteDetails && siteDetails.id ? siteDetails.id : null;
			
			const categories = await getSiteCategories(siteID);
			this.setState({categories});

		} catch(err) {
			throw new Error(err.message);
		}
	}

	renderDiscount() {
		console.log('render discount');
	}

	renderCategories() {
		const categories = this.state.categories;
		return Object.keys(categories).map((categoryID) => {
			const category = categories[categoryID];
			return <CategoryList key={categoryID} categoryID={categoryID} details={category} maxLimit={8}/>
		});
	}

	renderNewlyAdded() {
		return <NewlyAdded />
	}

	render() {
		return (
			<>
				<Container>
					{/* { this.renderDiscount() } */}
					{ this.renderNewlyAdded() }
					{ this.renderCategories() }
				</Container>
			</>
		)
	}
}