import React from 'react';
import { ProductCard } from './UI/Product';
import { CategoryDescription } from './UI/Category';
import { getCategoryProducts } from '../Pages/Product/helper';

export class CategoryList extends React.Component {
    constructor(props) {
        super();
        this.state = {
            products: {}
        };
    }

    async componentDidMount() {
        try {
            const products = await getCategoryProducts(this.props.categoryID, this.props.maxLimit);
            this.setState({products});
        } catch(err) {
            throw new Error(err.message);
        }
    }

    renderProductDesction() {
        return <CategoryDescription details={this.props.details}/>
    }

    renderCategoryProducts() {
        const products = this.state.products;
        return Object.keys(products).map((productID) => {
            const product = products[productID];
            return (
                <li className="col-6 col-lg-3 col-md-4 home-page-categorylist" key={productID}>
                    { ProductCard(productID, product) }
                </li>
            )
        });
    }

    render() {
        if(Object.keys(this.state.products).length === 0) return '';
        return (
            <>
                <section className="padding-bottom">
                    <header className="sectionx-heading heading-line">
                        <h4 className="title-section text-uppercase">{this.props.details.categoryName ?? ''}</h4>
                    </header>
                    <div className="card card-home-category">
                        <div className="row no-gutters">
                            <div className="col-md-3">
                                { this.renderProductDesction() }
                            </div>
                            <div className="col-md-9">
                                <ul className="row no-gutters bordered-cols">   
                                    { this.renderCategoryProducts() }
                                </ul>
                            </div> {/*  <!--  col.// --> */}
                        </div> {/*  <!--  row.// --> */}
                    </div> {/*  <!--  card.// --> */}
                </section>
            </> 
        )
    }
}