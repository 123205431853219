import React from 'react';
import { getQueryValue } from '../../globalhelper';
import { Container } from '../../Components/UI/Container';
import CustomerVerifyPage from './verify';
import CustomerLoginPage from './login';

export default class CustomerPage extends React.Component {
    constructor(props) {
        super();
        this.state = {
            screenType: null
        }
    }

    async componentDidMount() {
        try {

            const screenType = getQueryValue('screen');
            this.setState({screenType});

        } catch(err) {
            return;
        }
    }

    selectScreen = () => {
        switch(this.state.screenType) {
            case 'verify':
                return <CustomerVerifyPage />
            case 'login':
                return <CustomerLoginPage />
            default:
                return '';
          }
    }

    render() {
        return (
            <>
                <Container>
                    { this.selectScreen() }
                </Container>
            </>
        )
    }
}