import React from 'react';
import { NavBar } from './Header';
import { Footer } from '../Footer';

export function Container(props) {
    return (
        <>
            <NavBar />
            <div className="container">
                { props.children ?? '' }
            </div>
            <Footer />
        </>
    )
}