import React from 'react';
import LoginCustomer from '../../Components/UI/LoginCustomer';

export default class CustomerLoginPage extends React.Component {
    constructor(props) {
        super();
    }

    render() {
        return (
            <div class="card mx-auto" style={{maxWidth: '380px', marginTop: '50px', marginBottom: '50px' }}>
                <LoginCustomer />
            </div>
        )
    }
}