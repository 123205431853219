import React from 'react';
import { getCurrentSiteDetails } from '../../Pages/Home/helper';
import { isRegistered } from '../../Pages/Customer/helper';
import { isEmailValid, isMobileNumberValid, setPayload } from '../../globalhelper'

export default class VerifyCustomer extends React.Component {
    constructor(props) {
        super();
        this.state = {
            unique_identifier: ''
        }
        this.handleContinue = this.handleContinue.bind(this);
    }

    handleChange = (e) => {
        this.setState({[e.target.id]: e.target.value});
    }

    async handleContinue(e) {
        e.preventDefault();

        const uniqueIdentifier = this.state.unique_identifier;
        const siteDetails = await getCurrentSiteDetails();
        const payload =  {
            site_id: siteDetails.id
        };

        const isValidEmail = isEmailValid(uniqueIdentifier);
        if(isValidEmail) {
            payload.email_id = uniqueIdentifier;
        }

        const isValidMobileNumber = isMobileNumberValid(uniqueIdentifier);
        if(isValidMobileNumber) {
            payload.mobile_number = uniqueIdentifier;
        }

        const isCustomerRegistered = await isRegistered(payload);
        payload.is_registered = isCustomerRegistered;

        setPayload(payload, 'customerDetails');
        window.location.href = '/customer?screen=login';

    }

    render() {
        return (
            <div className="card-body">
                <h4 className="card-title mb-4">Customer Login/Registeration</h4>
                {/* <a href="#" className="btn btn-facebook btn-block mb-2"> <i className="fab fa-facebook-f"></i> &nbsp;  Sign in with Facebook</a>
                <a href="#" className="btn btn-google btn-block mb-4"> <i className="fab fa-google"></i> &nbsp;  Sign in with Google</a> */}
                <div className="form-group">
                    <input id="unique_identifier" name="unique_identifier" className="form-control" placeholder="Email ID/Mobile Number" type="text" value={this.state.unique_identifier}
                    onChange={this.handleChange}/>
                </div>
                <div className="form-group">
                    <button type="button" className="btn btn-primary btn-block" onClick={this.handleContinue}> Continue  </button>
                </div>
            </div>
        )
    }
}