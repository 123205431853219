import React from 'react';

export function CategoryDescription(props) {
    return (
        <div className="home-category-banner bg-light-orange">
            <h5 className="title">{props.details.headline ?? ''}</h5>
            <p>{props.details.description ?? ''}</p>
            <a href="/" className="btn btn-outline-primary rounded-pill">View All</a>
            {/* <img src="template/images/items/2.jpg" className="img-bg"/> */}
        </div>
    )
}
