import React from 'react';
import VerifyCustomer from '../../Components/UI/VerifyCustomer';

export default class CustomerVerifyPage extends React.Component {
    constructor(props) {
        super();
    }

    render() {
        return (
            <div class="card mx-auto" style={{maxWidth: '380px', marginTop: '50px', marginBottom: '50px' }}>
                <VerifyCustomer />
            </div>
        )
    }
}