import { Routes, Route } from "react-router-dom";
import HomePage from './Pages/Home';
import ProductPage from './Pages/Product';
import CustomerPage from './Pages/Customer';

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/home" element={<HomePage />} />
      <Route path="/products" element={<ProductPage />} />
      <Route path="/customer" element={<CustomerPage />} />
      {/* <Route path="about" element={<About />} /> */}
    </Routes>
  );
}

export default App;
