import { getData, setPayload, getPayload } from '../../globalhelper';

export async function getProductDetais(productID) {
    try {

        const type = `product-details-${productID}`;
        const productDetails = getPayload(type);
        if(productDetails && productDetails.value) return productDetails.value;

        const path = `/products/fetchById/${productID}`;
        const res = await getData(path);
        if(res.error && res.error.legth > 0)
            throw new Error(res.error);

        const payload = res.data && res.data.product ? res.data.product : {};
        setPayload(payload, type);

        return payload;

    } catch(err) {
        return [];
    }
}

export async function getSiteProducts(siteID, limit) {
    try {

        const siteProducts = getPayload('siteProducts');
        if(siteProducts && siteProducts.value) return siteProducts.value;

        const path = `/products/fetchBySiteId/${siteID}?limit=${limit}&order=desc`;
        const res = await getData(path);
        if(res.error && res.error.legth > 0)
            throw new Error(res.error);

        const responseSet = {};
        const payload = res.data && res.data.products ? res.data.products : [];
        for(const data of payload) {
            responseSet[data.id] = data;
        }
        setPayload(responseSet, 'siteProducts');

        return responseSet;

    } catch(err) {
        return [];
    }
}

export async function getCategoryProducts(categoryID, limit) {
    try {

        const categoryType = `categoryProducts-${categoryID}`;
        const categoryProducts = getPayload(categoryType) ?? {};
        if(categoryProducts && categoryProducts.value) return categoryProducts.value;

        const path = `/products/fetchByCategoryId/${categoryID}?limit=${limit}&order=desc`;
        const res = await getData(path);
        if(res.error && res.error.legth > 0)
            throw new Error(res.error);

        const responseSet = {};
        const payload = res.data && res.data.products ? res.data.products : [];
        for(const data of payload) {
            responseSet[data.id] = data;
        }
        setPayload(responseSet, categoryType);

        return responseSet;

    } catch(err) {
        return {};
    }
}