import React from 'react';
import { Container } from '../../Components/UI/Container';
import { ProductInformation, ProductDetails } from '../../Components/UI/Product';
import { getProductDetais } from './helper';
import { getCurrentSiteDetails } from '../Home/helper';
import { getCustomerDetails } from '../Customer/helper';
import { createOrder } from '../Order/helper';
import { getQueryValue, getPayload, setPayload, calculateDiscountedPrice } from '../../globalhelper';

export default class ProductPage extends React.Component {
    constructor(props) {
        super();
        this.state = {
            product: {},
            quantity: 1
        }
        this.addToCart = this.addToCart.bind(this);
    }

    async componentDidMount() {
        try {

            const pageID = getQueryValue('id');
            if(!pageID || pageID.length === 0) return;
            
            const product = await getProductDetais(pageID);
            this.setState({product});

        } catch(err) {
            throw new Error(err.message);
        }
    }


    changeQuantity = (e) => {
        try {
            const self = this;
            const value = e.target.value < 1 ? 1 : e.target.value;
            self.setState({
                quantity: value
            });
        } catch(err) {
            return;
        }
    }

    increaseQuantity = () => {
        try {
            const self = this;
            self.setState({
                quantity: self.state.quantity + 1
            });
        } catch(err) {
            return;
        }
    }

    decreaseQuantity = () => {
        try {
            const self = this;
            const quantity = self.state.quantity;
            if(quantity === 1) return;
            self.setState({
                quantity: quantity - 1
            });
        } catch(err) {
            return;
        }
    }

    async addToCart() {
        try {
            const self = this;
            const currentSiteLocation = window.location.href;
            const customerDetails = await getCustomerDetails();
            if(!customerDetails) {
                setPayload({redirectUrl: currentSiteLocation}, 'redirectUrl');
                window.location.href='/customer?screen=verify';
                return;
            }

            const siteDetails = await getCurrentSiteDetails();
            const productDetails = self.state.product;
            const quantity = self.state.quantity;
            const payload = {
                site_id: siteDetails.id,
                product_id: productDetails.id,
                customer_id: customerDetails.id,
                label: productDetails.productName,
                quantity: quantity,
                amount: calculateDiscountedPrice(productDetails.price, productDetails.discountPer)
            };

            // create payload
            await createOrder(payload);
            
        } catch(err) {
            return;
        }
    }

    render() {
		return (
			<>
				<Container>
                    <ProductInformation key={this.state.quantity}
                    details={this.state.product} 
                    quantity={this.state.quantity} 
                    increaseQuantity={this.increaseQuantity} 
                    decreaseQuantity={this.decreaseQuantity}
                    changeQuantity={this.changeQuantity}
                    addToCart={this.addToCart}/>
                    <ProductDetails details={this.state.product}/>
				</Container>
			</>
		)
	}
}