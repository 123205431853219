import React from 'react';
import { getCurrentSiteDetails } from '../Pages/Home/helper';
import { getSiteCategories } from '../Pages/Category/helper';
import { getSiteBrands } from '../Pages/Brand/helper';

export class Footer extends React.Component {
    constructor(props) {
        super();
        this.state = {
            footerItems: {
                brands: {
                    label: 'brands',
                    entries: {}
                },
                categories: {
                    label: 'categories',
                    entries: {}
                },
                social: {
                    label: 'social',
                    entries: {
                        facebook: {
                            label: 'Facebook'
                        },
                        twitter: {
                            label: 'Twitter'
                        },
                        instagram: {
                            label: 'Instagram'
                        },
                        youtube: {
                            label: 'Youtube'
                        }
                    }
                }
            }
        };
    }

    async componentDidMount() {
        try {

            const self = this;
            const siteDetails = await getCurrentSiteDetails();
            const siteID = siteDetails && siteDetails.id ? siteDetails.id : null;
            if(!siteID) return;

            const categories = await getSiteCategories(siteID);
            const brands = await getSiteBrands(siteID);

            const selectedCategoryKeys = {};
            for(const categoryID in categories) {
                const category = categories[categoryID];
                selectedCategoryKeys[categoryID] = {
                    categoryName: category.categoryName
                }
            }
            
            self.setState({
                footerItems: {
                    brands: {
                        label: self.state.footerItems.brands.label,
                        entries: brands
                    },
                    categories: {
                        label: self.state.footerItems.categories.label,
                        entries: selectedCategoryKeys
                    },
                    social: {
                        label: self.state.footerItems.social.label,
                        entries: self.state.footerItems.social.entries
                    }
                }
            });

        } catch(err) {
            throw new Error(err.message);
        }
    }
    
    listItem(type, id, entries) {
        const link = `/${type}?id=${id}`;
        return Object.keys(entries).map((entry) => {
            const item = entries[entry];
            return <li key={entry}> <a href={link}>{item}</a></li>
        });
    }

    listContainer(label, entries) {
        const entriesList = Object.keys(entries).map((entry) => {
            const item = entries[entry];
            return this.listItem(label, entry, item);
        });
        return (
            <aside className="col-md col-6" key={label}>
                <h6 className="title">{ label }</h6>
                <ul className="list-unstyled">
                    { entriesList }
                </ul>
            </aside>
        )
    }

    renderItems() {
        const self = this;
        return Object.keys(this.state.footerItems).map((entry) => {
            const item = this.state.footerItems[entry];
            return self.listContainer(item.label, item.entries);
        });
    }

    render() {
        return (
            <footer className="section-footer bg-secondary">
                <div className="container">
                    <section className="footer-top padding-y-lg text-white">
                        <div className="row">
                            { this.renderItems() }
                            <aside className="col-md col-6">
                                <h6 className="title">Help</h6>
                                <ul className="list-unstyled">
                                    <li> <a href="/">Contact us</a></li>
                                    <li> <a href="/">Money refund</a></li>
                                    <li> <a href="/">Order status</a></li>
                                    <li> <a href="/">Shipping info</a></li>
                                    <li> <a href="/">Open dispute</a></li>
                                </ul>
                            </aside>
                            <aside className="col-md col-6">
                                <h6 className="title">Account</h6>
                                <ul className="list-unstyled">
                                    <li> <a href="/"> User Login </a></li>
                                    <li> <a href="/"> User register </a></li>
                                    <li> <a href="/"> Account Setting </a></li>
                                    <li> <a href="/"> My Orders </a></li>
                                </ul>
                            </aside>
                        </div> {/*  <!--  row.// --> */}
                    </section>	{/*  <!--  footer-top.// --> */}

                    <section className="footer-bottom text-center">
                        <p className="text-white">Privacy Policy - Terms of Use - User Information Legal Enquiry Guide</p>
                        <p className="text-muted"> &copy 2019 Company name, All rights reserved </p>
                        <br/>
                    </section>
                </div>{/*  <!--  //container --> */}
            </footer>
        )
    }
}