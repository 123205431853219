import { getPayload, postData, setPayload } from '../../globalhelper';

export async function getCustomerDetails() {
    try {
        const customerDetails = getPayload('customerDetails');
        const customerInfo = customerDetails && customerDetails.value ? customerDetails.value : {};
        return customerInfo;
    } catch(err) {
        return;
    }
}

export async function register(payload) {
    try {

        const customerRegistered = await postData('/customers', payload);
        if(customerRegistered && customerRegistered.data && customerRegistered.data.customer)
            setPayload(customerRegistered.data.customer, 'customerDetails');

    } catch(err) {
        return;
    }
}

export async function isVerified(payload) {
    let isCustomerVerified= false;
    try {

        const verification = await postData('/customers/verify', payload);
        if(verification && verification.data && verification.data.isVerified) {
            isCustomerVerified = verification.data.isVerified;
            setPayload(verification.data.customer, 'customerDetails');
        }

    } catch(err) {
        isCustomerVerified = false;
    }
    return isCustomerVerified;
}

export async function isRegistered(payload) {

    let isCustomerRegistered = false;
    try {

        const registerationCheck = await postData('/customers/isRegistered', payload);
        if(registerationCheck && registerationCheck.data && registerationCheck.data.isRegistered)
            isCustomerRegistered = registerationCheck.data.isRegistered;

    } catch(err) {
        isCustomerRegistered = false;
    }

    return isCustomerRegistered;

}
