import crypto from 'crypto';
import config from './config';

/**
 * 
 * @param {*} path : api path
 * @param {*} payload : post data
 */
export async function postData(path, payload = null, method = 'POST') {
    try {
        const token = userToken();
        const currentSiteToken = siteToken();
        const res = await fetch(`${config.api.baseUrl}${path}`, {
            method: method,
            body: JSON.stringify(payload), 
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-key': config.api.key,
                'x-token': token,
                'x-site-token': currentSiteToken,
                'x-host': config.host ?? null,
            }
        });
        return res.json();
    } catch (err) {
        return;
    }
}

/**
 * 
 * @param {*} path : api path
 */
export async function getData(path) {
    try {
        const token = userToken();
        const currentSiteToken = siteToken();
        const res = await fetch(`${config.api.baseUrl}${path}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-key': config.api.key,
                'x-token': token,
                'x-site-token': currentSiteToken,
                'x-host': config.host ?? null,
            }
        });
        return res.json();
    } catch (err) {
        console.log(err);
        return;
    }
}

/**
 * 
 * @param {*} key : key from the url
 */
export function getQueryValue (key) {  
    if(!key)
        return;
    return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));  
} 

/**
 * 
 * @param {*} key : value of the key from the path
 */
export function urlParam(key = null) {
    let pathName = window.location.pathname;
    let value = pathName.split('/');

    // if key is not null
    if(!key)
        return value;
    else
        return value[key];
}

// random string generator
export function randomString() {
    return '_' + Math.random().toString(36).substr(2, 9);
}

// set user data
export function setUserData(payload) {
    payload = JSON.stringify(payload);
    let salt = '335d67ab-5e32-43e9-ac8c-99e050c38ad7';
    let key = crypto.createCipher('aes-128-cbc', salt);
    let str = key.update(payload, 'utf8', 'hex')
    str += key.final('hex');
    localStorage.setItem('user', str);
}

// get user data
export function getUserData() {
    let payload = localStorage.getItem('user');
    if(payload) {
        let salt = '335d67ab-5e32-43e9-ac8c-99e050c38ad7';
        let key = crypto.createDecipher('aes-128-cbc', salt);
        let str = key.update(payload, 'hex', 'utf8')
        str += key.final('utf8');
        payload = JSON.parse(str);
    }
    return payload;
}

// set user data
export function setPayload(payload, type = null, ttl = 900000) {
    const now = new Date();
    let res = getPayload() || {};
    res[type] = {
        value: payload,
        expiry: now.getTime() + ttl
    };
    payload = JSON.stringify(res);
    let salt = '335d67ab-5e32-43e9-ac8c-99e050c38ad7';
    let key = crypto.createCipher('aes-128-cbc', salt);
    let str = key.update(payload, 'utf8', 'hex')
    str += key.final('hex');
    localStorage.setItem('payload', str);
}

// get user data
export function getPayload(type = null) {
	const now = new Date();
    let payload = localStorage.getItem('payload');
    if(payload) {
        let salt = '335d67ab-5e32-43e9-ac8c-99e050c38ad7';
        let key = crypto.createDecipher('aes-128-cbc', salt);
        let str = key.update(payload, 'hex', 'utf8')
        str += key.final('utf8');
        payload = JSON.parse(str);
        payload = !type ? payload : payload[type];
        if (payload && payload.expiry && now.getTime() > payload.expiry) {
            // localStorage.removeItem(type);
            // remove key from payload
            console.log(`${type} :: expired`);
        }
    }
    return payload;
}

// remove user data
export function removeUserData() {
    localStorage.removeItem('user');
}

//  get usertoken from the stored user data
function userToken() {
    let accessToken = getPayload('access_token');
    if(accessToken && accessToken.access_token && accessToken.access_token.length > 0)
        return accessToken.access_token;
    return null;
}

//  get sitetoken from the stored site data
function siteToken() {
    const siteInfo = getPayload('siteDetails');
    const siteDetails = siteInfo && siteInfo.value ? siteInfo.value : {};
    if(siteDetails && siteDetails.siteToken && siteDetails.siteToken.length > 0)
        return siteDetails.siteToken;
    
    return null;
}

// get first charater
export function getFirstCharacter(text) {
    if(text && text.length > 0) 
        return text.charAt(0)
    return '';
}

export function truncateText(text, maxSize = 25) {
    if (text.length > maxSize) {
        text = text.substring(0, 24) + "...";
    }
    return text;
}

//capitalize only the first letter of the string. 
export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function calculateDiscountedPrice(amount, discountPer = null) {
    try {
        if(discountPer) {
            const discountPrice = parseInt(discountPer)/100;
            const price = parseInt(amount);
            return (price - (discountPrice * price));
        }
        return amount;
    } catch(err) {
        throw new Error(err.message);
    }
} 

export function isEmailValid (emailAdress) {
    try {
        const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (emailAdress.match(regexEmail)) return true; 
        else return false; 
    } catch(err) {
        return false;
    }
}

export function isMobileNumberValid(mobileNumber) {
    try {
        // Regular expression to check if string is a Indian mobile number
        const regexExp = /^[6-9]\d{9}$/gi;
        return regexExp.test(mobileNumber);
    } catch(err) {
        return false;
    }
}
  