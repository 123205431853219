import { getData, setPayload, getPayload } from '../../globalhelper';

export async function getSiteBrands(siteID) {
    try {

        const brands = getPayload('brands');
        if(brands && brands.value) return brands.value;

        const path = `/brands/fetchBySiteId/${siteID}`;
        const res = await getData(path);
        if(res.error && res.error.legth > 0)
            throw new Error(res.error);

        const responseSet = {};
        const payload = res.data && res.data.brands ? res.data.brands : [];
        for(const data of payload) {
            responseSet[data.id] = {
                brandName: data.brandName
            };
        }
        setPayload(responseSet, 'brands');

        return responseSet;

    } catch(err) {
        return {};
    }
}