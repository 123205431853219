import { getData, setPayload, getPayload } from '../../globalhelper';
import config from '../../config';

export async function getCurrentSiteDetails() {
    try {

        const siteDetails = getPayload('siteDetails');
        if(siteDetails && siteDetails.value) return siteDetails.value;

        const host = config.host ?? window.location.host;
        const path = `/sites/fetchByDomain/${host}`;
        const res = await getData(path);
        if(res.error && res.error.legth > 0)
            throw new Error(res.error);

        const siteResDetails = res.data && res.data.site ? res.data.site : null;
        setPayload(siteResDetails, 'siteDetails');

        return siteResDetails;

    } catch(err) {
        return {};
    }
}