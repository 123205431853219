import React from 'react';
import { getCurrentSiteDetails } from '../Pages/Home/helper';
import { getSiteProducts } from '../Pages/Product/helper';
import { ShowCasedProductCard } from './UI/Product';

export class NewlyAdded extends React.Component {
    constructor(props) {
        super();
        this.state = {
            products: {}
        }
    }

    async componentDidMount() {
        try {
            const siteDetails = await getCurrentSiteDetails();
            const siteID = siteDetails && siteDetails.id ? siteDetails.id : null;
            if(!siteID) return;
            const products = await getSiteProducts(siteID, 12);
            this.setState({products});
        } catch(err) {
            throw new Error(err.message);
        }
    }

    renderProducts() {
        const products = this.state.products;
        return Object.keys(products).map((productID) => {
            const productData = products[productID];
            return (
                <div className="col-xl-2 col-lg-3 col-md-4 col-6" key={productID}>
                    { ShowCasedProductCard(productID, productData) }
                </div>
            )
        });
    }

    render() {
        return (
            <section className="padding-bottom-sm">
                <header className="section-heading heading-line">
                    <h4 className="title-section text-uppercase">New Additions</h4>
                </header>
                <div className="row row-sm">
                    { this.renderProducts() }
                </div> 
            </section>
        )
    }
}