import React from 'react';
import { getCustomerDetails, isVerified, register } from '../../Pages/Customer/helper';
import { getPayload, setPayload } from '../../globalhelper'

export default class LoginCustomer extends React.Component {
    constructor(props) {
        super();
        this.state = {
            pageLabel: '',
            customerInfo: {},
            password: ''
        }
        this.handleContinue = this.handleContinue.bind(this);
    }

    componentDidMount() {
        try {

            const customerInfo = getCustomerDetails();

            let pageLabel = 'Customer Login';
            if(!customerInfo.is_registered)
                pageLabel = 'Customer Registeration';

            this.setState({
                pageLabel: pageLabel,
                customerInfo: customerInfo
            });

        } catch(err) {
            return;
        }
    }

    handleChange = (e) => {
        this.setState({[e.target.id]: e.target.value});
    }

    async handleContinue(e) {
        e.preventDefault();

        const customerInfo = this.state.customerInfo;
        const payload =  customerInfo;
        payload.password = this.state.password;

        // if customer is registered, login
        let redirect = false;
        if(customerInfo.is_registered) {
            const isCustomerVerified = await isVerified(payload);
            if(isCustomerVerified) {
                console.log(payload);
                redirect = true;
            }
        }

        if(!customerInfo.is_registered) {
            await register(payload);
            redirect = true;
        }

        if(redirect) {
            const redirectDetails = getPayload('redirectUrl');
            const redirectUrl = redirectDetails.value && redirectDetails.value.redirectUrl ? redirectDetails.value.redirectUrl : '/';
            if(redirectUrl)
                window.location.href = redirectUrl;
        }

    }

    render() {
        return (
            <div className="card-body">
                <h4 className="card-title mb-4">{this.state.pageLabel}</h4>
                {/* <a href="#" className="btn btn-facebook btn-block mb-2"> <i className="fab fa-facebook-f"></i> &nbsp;  Sign in with Facebook</a>
                <a href="#" className="btn btn-google btn-block mb-4"> <i className="fab fa-google"></i> &nbsp;  Sign in with Google</a> */}
                <div className="form-group">
                    <input id="password" name="password" className="form-control" placeholder="Password" type="password" value={this.state.unique_identifier}
                    onChange={this.handleChange}/>
                </div>
                <div className="form-group">
                    <button type="button" className="btn btn-primary btn-block" onClick={this.handleContinue}> Continue  </button>
                </div>
            </div>
        )
    }
}